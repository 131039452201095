.rules-card {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    width: 100%;
    height: 80%;
    min-height: 350px;
    margin: auto;
    display: block;
    color: #292C34;
    text-align: center;
    margin-top: 10%;
    padding-top: 10%;
}

.round-title {
    padding: 10%;
    font-size: calc(15px + 2vmin);
    font-weight: bold;
}

.round-rules {
    padding: 10%;
}