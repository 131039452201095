.score {
    margin-left: 20%;
    margin-right: 20%;
    text-align: left;
    padding: 5%;
}

.logo {
    margin-top: 50px;
    display: block;
    max-height: 250px;
  }
  
  img {
    height: 40vmin;
    max-height: 230px;
    pointer-events: none;
  }