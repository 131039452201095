.timer {
    margin: 3% auto;
    width: 80px;
    padding: 8px 15px;
    background-color:#5F6167;
    border-radius: 5%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

    