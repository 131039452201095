.Home {
    text-align: center;
  }

  .Home-logo {
    width: 60%;
    min-width: 200px;
    pointer-events: none;
  }
  
  .Home-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Home-link {
    color: #61dafb;
  }

 .TitleImg {
    margin-top: 5%;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 300px;
    height: 100%;
    cursor: pointer;
  }

 .TitleImg > svg {
    margin: 0 auto;
    max-height: 275px;
    max-width: 800pt;
  }

  .intro {
    padding-left: 20%;
    padding-right: 20%;
  }

  button:disabled {
    opacity: 40% !important;
    pointer-events: none;
}

.small-text {
  font-size: calc(8px + 2vmin);
  padding-left: 15%;
  padding-right: 15%;
}