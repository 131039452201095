button {
    background-color: #e7e7e7;
    color: black;
    border: none;
    padding: 14px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }

  button {
    transition-duration: 0.4s;
  }
  
  button:hover {
    background-color: #F4F7B4;
    color: rgb(27, 27, 27);
  }

.team-input {
    width: 30% !important;
    min-width: 100px;
    padding: 12px 20px;
    box-sizing: border-box;
    font-size: 16px;
    margin: 2%;
}

.pink {
  border: 3px solid#F25577;
}

.blue {
  border: 3px solid #2EDDCB;
}

.pink:focus {
    background-color: rgb(243, 101, 131);
    color: white;
}

.blue:focus {
  background-color: rgb(129, 236, 226);
  color: rgb(27, 27, 27);
}
