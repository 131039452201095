/* Style the header */
.header {
    position: fixed;
    top: 0;
    padding: 10px 16px;
    padding-left: 60px;
    background: #5F6167;
    color: #f1f1f1;
    display: block;
    width: 100%;
    text-align: left;
    font-size: 15px;
    line-height: 30px;
  }

  .support-link {
    color: #292C34;
  }

  .support {
    background: #F25577 !important;
  }
  
  /* Page content */
  .content {
    padding: 16px;
  }
  
  /* The sticky class is added to the header with JS when it reaches its scroll position */
  .sticky {
    position: fixed;
    top: 0;
    width: 100%
  }
  
  /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .content {
    padding-top: 2px;
  }