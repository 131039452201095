.Game {
    text-align: center;
  }

.Game-page {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Game-link {
  color: #2EDDCB;
}

.title {
  margin-top: 50px;
  margin-bottom: 50px;
}

.round {
  margin-bottom: 0px;
}

.round-name {
  padding: 10px 16px;
  margin: auto;
  color: #f1f1f1;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 30px;
  margin-bottom: 15px;
}

.logo {
  margin-top: 50px;
  display: block;
  max-height: 250px;
}

img {
  height: 40vmin;
  max-height: 230px;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .title {
    margin-top: 100px;
  }
}

button:disabled {
  opacity: 40% !important;
  pointer-events: none;
}